<template>
  <div :class="[$attrs.class, 'scroll-custom flex min-h-full w-full flex-col']">
    <TheHeader />

    <div
      class="mt-[46px] flex flex-col md:mt-[82px] lg:mt-0 min-h-[calc(100vh-300px)]"
    >
      <slot />
    </div>

    <TheFooter />

    <ContactModal />

    <CommonToast
      :show="toast.show"
      :position="toast.position"
      :type="toast.type"
      :message="toast.message"
      :duration="toast.duration"
      :icon="toast.icon"
      @close="closeToast"
    />
  </div>
</template>

<script lang="ts" setup>
import TheHeader from '~/components/TheHeader.vue'
import TheFooter from '~/components/TheFooter.vue'
import { useToastStore } from '~/stores/toast'

const toast = useToastStore()
const closeToast = () => toast.clearToast()
</script>
